import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from './Firebase/firebaseConfig';
import { useAptabase } from '@aptabase/react';

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {

  const { trackEvent } = useAptabase();
  const [currentUser, setCurrentUser] = useState(() => {
    const savedUser = localStorage.getItem('currentUser');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [loading, setLoading] = useState(true);

  const fetchUserData = useCallback(async (user) => {
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            const userToStore = {
              uid: user.uid,
              email: user.email,
              username: userData.username,
              isAdmin: userData.isAdmin,
              profilePicture: userData.profilePicture,
              receiveNewsletter: userData.receiveNewsletter, // Ajout de la préférence newsletter
              isPro: userData.isPro
            };

            trackEvent('user', { email: user.email });


            setCurrentUser(userToStore);
            localStorage.setItem('currentUser', JSON.stringify(userToStore));
          }
        }, (error) => {
          console.error("Error fetching user data:", error);
          setCurrentUser(null);
          localStorage.removeItem('currentUser');
        });

        return () => unsubscribeSnapshot();
      } catch (error) {
        console.error("Error fetching user data:", error);
        setCurrentUser(null);
        localStorage.removeItem('currentUser');
      } finally {
        setLoading(false);
      }
    } else {

      setCurrentUser(null);
      localStorage.removeItem('currentUser');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setLoading(true);
      fetchUserData(user);
    });

    return () => unsubscribeAuth();
  }, [fetchUserData]);

  const contextValue = useMemo(() => ({ currentUser, loading }), [currentUser, loading]);

  console.log(currentUser)

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
