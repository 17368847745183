import React, { useState, useContext, useEffect } from "react";
import { collection, addDoc, query, where, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig";
import UserContext from "../components/UserContext";
import { Button, Textarea, Link } from "@nextui-org/react";
import { Send } from "iconsax-react";
import { formatDistanceToNow } from 'date-fns';
import { addCommentNotification, addReplyNotification } from "./Firebase/addLikeNotification";
import { checkProfanity } from './PerspectiveService';
import { useTranslation } from 'react-i18next';

const formatTime = (date) => {
    if (!date || isNaN(date)) return "";
    let result = formatDistanceToNow(date, { addSuffix: false });
    result = result.replace(/about (\d+) hours?/, '$1 h').replace(/(\d+) minutes?/, '$1 min');
    return result;
};

const ImageWithComments = ({ imageId, imageOwnerId, inputRef }) => {
    const { currentUser } = useContext(UserContext);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [replies, setReplies] = useState([]);
    const [reply, setReply] = useState({});
    const [showReply, setShowReply] = useState(null);
    const [showAllComments, setShowAllComments] = useState(false);
    const { t } = useTranslation();

    // Charger les commentaires de l'image
    useEffect(() => {
        if (!imageId) return;
        const q = query(collection(db, "comments"), where("imageId", "==", imageId));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const commentsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            commentsList.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
            setComments(commentsList);
        });
        return () => unsubscribe();
    }, [imageId]);

    // Charger toutes les réponses
    useEffect(() => {
        const qReplies = query(collection(db, "replies"));
        const unsubscribeReplies = onSnapshot(qReplies, (snapshot) => {
            const repliesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setReplies(repliesList);
        });
        return () => unsubscribeReplies();
    }, []);

    // Gérer l'envoi de commentaire
    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!currentUser) return alert("Please log in to comment.");
        if (!comment.trim()) return alert("Comment cannot be empty.");

        const isProfane = await checkProfanity(comment);
        if (isProfane) return alert("Your comment contains inappropriate language.");

        const newComment = { userId: currentUser.uid, imageId, comment, userName: currentUser.username || currentUser.email, createdAt: new Date() };
        await addDoc(collection(db, "comments"), newComment);
        setComment("");
        await addCommentNotification(currentUser.username || currentUser.email, currentUser.uid, imageId, imageOwnerId, comment);
    };

    // Gérer l'envoi de réponse
    const handleReplySubmit = async (commentId, replyText, commentOwnerId) => {
        if (!currentUser) return alert("Please log in to reply.");
        if (!replyText.trim()) return alert("Reply cannot be empty.");

        const isProfane = await checkProfanity(replyText);
        if (isProfane) return alert("Your reply contains inappropriate language.");

        const newReply = {
            userId: currentUser.uid,
            commentId,
            reply: replyText,
            userName: currentUser.username || currentUser.email,
            createdAt: new Date(),
        };
        await addDoc(collection(db, "replies"), newReply);
        setReply((prev) => ({ ...prev, [commentId]: "" }));
        setShowReply(null);
        await addReplyNotification(currentUser.username || currentUser.email, currentUser.uid, imageId, commentId, commentOwnerId, replyText);
    };

    // Supprimer un commentaire
    const handleDelete = async (commentId) => {
        await deleteDoc(doc(db, "comments", commentId));
    };

    // Supprimer une réponse
    const handleDeleteReply = async (replyId) => {
        await deleteDoc(doc(db, "replies", replyId));
    };

    return (
        <div>
            {/* Formulaire d'envoi de commentaire */}
            <form className="flex items-stretch h-[40px]" onSubmit={handleCommentSubmit}>
                <Textarea
                    className="flex-grow mr-2 h-[40px]"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder={t("writecomment")}
                    variant="underlined"
                    size="sm"
                    ref={inputRef}
                />
                <Button isIconOnly size="sm" className="flex-shrink-0 h-auto m-0" variant="Light" color="primary" type="submit">
                    <Send size="18" variant="Bold" className="svg-primary-200" />
                </Button>
            </form>

            {/* Affichage des commentaires */}
            {(showAllComments ? comments : comments.slice(0, 2)).map((c) => (
                <div key={c.id} className="mt-5">
                    <div className="flex flex-col gap-1 bg-default-50 p-3 rounded-md justify-between items-start">
                        <div className="flex justify-between w-full">
                            <Link href={`/profile/${c.userName || "Unknown"}`} className="text-sm cursor-pointer hover:underline text-default-700 font-medium">
                                {c.userName || "Unknown"}
                            </Link>
                            <p className="text-xs text-default-500">{formatTime(new Date(c.createdAt.seconds * 1000))}</p>
                        </div>

                        <div className="flex justify-between w-full items-end" >
                            <p className="text-default-600">{c.comment}</p>

                            <div className="flex gap-2">
                                {currentUser && currentUser.uid === c.userId && (
                                    <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => handleDelete(c.id)}>
                                        Delete
                                    </Link>
                                )}
                                <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => setShowReply(c.id === showReply ? null : c.id)}>
                                    Reply
                                </Link>
                            </div>
                        </div>

                    </div>

                    {/* Formulaire de réponse */}
                    {c.id === showReply && (
                        <div className="flex my-4 ml-16">
                            <Textarea
                                variant="underlined"
                                className="flex-grow mr-2"
                                value={reply[c.id] || ""}
                                onChange={(e) => setReply({ ...reply, [c.id]: e.target.value })}
                                placeholder="Write a reply..."
                                minRows={1}
                            />
                            <Button
                                className="flex-shrink-0 h-auto"
                                color="default"
                                variant="light"
                                isIconOnly
                                onClick={() => handleReplySubmit(c.id, reply[c.id], c.userId)}
                            >
                                <Send size="18" variant="Bold" />
                            </Button>
                        </div>
                    )}

                    {/* Affichage des réponses du commentaire */}
                    {replies
                        .filter(r => r.commentId === c.id) // Affiche toutes les réponses liées au commentaire
                        .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
                        .map((r) => (
                            <div key={r.id} >

                                <div className="ml-16 mt-2 bg-default-50 p-3 rounded-md flex gap-1 flex-col" >

                                    <div className="flex justify-between w-full items-start ">
                                        <Link href={`/profile/${r.userName || "Unknown"}`} className="text-sm cursor-pointer hover:underline text-default-700 font-medium">
                                            {r.userName || "Unknown"}
                                        </Link>
                                        <p className="text-xs text-default-500">{formatTime(new Date(r.createdAt.seconds * 1000))}</p>
                                    </div>

                                    <div className="flex justify-between items-end" >
                                        <p className="text-default-600">{r.reply}</p>

                                        <div className="flex gap-2">
                                            {currentUser && currentUser.uid === r.userId && (
                                                <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => handleDeleteReply(r.id)}>
                                                    Delete
                                                </Link>
                                            )}
                                            <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => setShowReply(r.id === showReply ? null : r.id)}>
                                                Reply
                                            </Link>
                                        </div>
                                    </div>


                                </div>


                                {r.id === showReply && (
                                    <div className="flex my-2 ml-16">
                                        <Textarea
                                            variant="underlined"
                                            className="flex-grow mr-2"
                                            value={reply[r.id] || ""}
                                            onChange={(e) => setReply({ ...reply, [r.id]: e.target.value })}
                                            placeholder="Reply to this reply..."
                                            minRows={1}
                                        />
                                        <Button
                                            className="flex-shrink-0 h-auto"
                                            color="default"
                                            variant="light"
                                            isIconOnly
                                            onClick={() => handleReplySubmit(r.commentId, reply[r.id], r.userId)}
                                        >
                                            <Send size="18" variant="Bold" />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            ))}

            {/* Afficher tous les commentaires si nécessaire */}
            {!showAllComments && comments.length > 2 && (
                <div className="mt-2">
                    <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => setShowAllComments(true)}>
                        Show all comments
                    </Link>
                </div>
            )}
        </div>
    );
};

export default ImageWithComments;
