import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, Tabs, Tab, Checkbox } from '@nextui-org/react';
import { auth } from "./Firebase/firebaseConfig";
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { getStorage, deleteObject, ref } from 'firebase/storage';
import UserContext from "./UserContext";
import { ClipboardText, Microscope, ExportSquare } from "iconsax-react";
import SetupForm from './SetupForm';
import { useTranslation } from 'react-i18next';
import { FilloutPopupEmbed } from '@fillout/react';
import "@fillout/react/style.css";

const SettingsModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [receiveNewsletter, setReceiveNewsletter] = useState(null); // Init à null pour attendre la valeur de la DB
    const { currentUser } = useContext(UserContext); // Utilisation du contexte pour accéder à l'utilisateur
    const db = getFirestore();
    const storage = getStorage();
    const { t } = useTranslation();


    // Chargement de la préférence de newsletter une fois que l'utilisateur est disponible
    // Chargement initial de la préférence de newsletter une fois que l'utilisateur est disponible
    useEffect(() => {
        if (currentUser) {
            setReceiveNewsletter(currentUser.receiveNewsletter || false); // Utilisation directe du booléen depuis Firestore
        }
    }, [currentUser]);

    

    const handleLogout = async () => {
        setIsLoading(true);
        try {
            await auth.signOut();
            onClose(); // Fermer la modale après déconnexion
            navigate('/login'); // Rediriger vers la page de connexion après la déconnexion
        } catch (error) {
            console.error("Error signing out:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteUserRelatedData = async (userId) => {
        try {
            // Suppression des images de l'utilisateur
            const imagesQuery = query(collection(db, 'images'), where('author', '==', userId));
            const imagesSnapshot = await getDocs(imagesQuery);
            imagesSnapshot.forEach(async (doc) => {
                const imageRef = ref(storage, doc.data().imagePath);
                await deleteObject(imageRef);
                await deleteDoc(doc.ref);
            });

            // Suppression des commentaires de l'utilisateur
            const commentsQuery = query(collection(db, 'comments'), where('userId', '==', userId));
            const commentsSnapshot = await getDocs(commentsQuery);
            commentsSnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

            // Suppression des réponses de l'utilisateur
            const repliesQuery = query(collection(db, 'replies'), where('userId', '==', userId));
            const repliesSnapshot = await getDocs(repliesQuery);
            repliesSnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

            // Suppression des likes de l'utilisateur
            const likesQuery = query(collection(db, 'likes'), where('userId', '==', userId));
            const likesSnapshot = await getDocs(likesQuery);
            likesSnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

            // Suppression du document utilisateur
            await deleteDoc(doc(db, 'users', userId));
        } catch (error) {
            console.error("Error deleting user related data:", error);
        }
    };

    const handleDeleteAccount = async () => {
        setIsLoading(true);
        try {
            const currentUser = auth.currentUser;
            const credential = EmailAuthProvider.credential(currentUser.email, password);
            await reauthenticateWithCredential(currentUser, credential);

            // Suppression des données liées à l'utilisateur
            await deleteUserRelatedData(currentUser.uid);

            // Suppression du compte utilisateur
            await currentUser.delete();
            onClose(); // Fermer la modale après suppression de compte
            navigate('/'); // Rediriger vers la page d'accueil après suppression de compte
        } catch (error) {
            console.error("Error deleting account:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleButtonClick = () => {
        if (confirmDelete) {
            handleDeleteAccount();
        } else {
            setConfirmDelete(true);
        }
    };

    const handleNavigate = (url) => {
        window.open(url, '_blank');
    };


    // Mise à jour de la préférence de newsletter dans Firestore
    const updateNewsletterPreference = async (userId, isChecked) => {
        try {
            const userDocRef = doc(db, 'users', userId);
            await updateDoc(userDocRef, { receiveNewsletter: isChecked });
            console.log("Newsletter preference updated successfully");
        } catch (error) {
            console.error("Error updating newsletter preference:", error);
        }
    };

    // Gestion du changement de la checkbox de la newsletter
    const handleNewsletterChange = async (e) => {
        const isChecked = e.target.checked;
        setReceiveNewsletter(isChecked); // Met à jour l'état local
        if (currentUser) {
            await updateNewsletterPreference(currentUser.uid, isChecked); // Met à jour Firestore
        }
    };

    return (
        <Modal size='5xl' scrollBehavior='inside' placement="center" isOpen={isOpen} onOpenChange={onClose} className="purple-dark text-foreground h-5/6">
            <ModalContent>
                <>
                    <ModalHeader>{t('settings.title')}</ModalHeader>
                    <ModalBody >
                        <Tabs variant='underlined'>
                            <Tab title={
                                <div className="flex items-center space-x-1">
                                    <ClipboardText size={18} variant="Bold" />
                                    <span>{t('settings.general')}</span>
                                </div>
                            }>
                                <div className="flex flex-col gap-6">
                                    <div className="flex flex-row gap-2 justify-between items-center">
                                        <div>
                                            <p>{t('settings.reportBug')}</p>
                                            <p className='text-default-500'>{t('settings.reportBugDescription')}</p>
                                        </div>


                                        <Button>
                                            <a
                                                href="https://forms.fillout.com/t/cNXce3WMzeus"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                            >
                                                Contact us
                                            </a>
                                            <ExportSquare size={16} variant="linear" />
                                        </Button>


                                    </div>


                                    {isContactOpen && (
                                        <div>
                                            <FilloutPopupEmbed
                                                filloutId="cNXce3WMzeus"
                                                onClose={() => setIsContactOpen(false)}
                                            />
                                        </div>
                                    )}

                                    <div className="flex flex-row gap-2 justify-between items-center">
                                        <div>
                                            <p>{t('settings.roadmap')}</p>
                                            <p className='text-default-500'>{t('settings.roadmapDescription')}</p>
                                        </div>

                                        <Button onClick={() => handleNavigate('https://amusing-attic-3a5.notion.site/7e6c348d4e3b4a7bb48434db46f337f3?v=bd0d05d499bf44daaf45bdaff6037872')} disabled={isLoading}>
                                            {t('settings.openRoadmap')}
                                            <ExportSquare size={16} variant="linear" />
                                        </Button>
                                    </div>

                                    <div className="flex flex-row gap-2 justify-between items-center">
                                        <div>
                                            <p>Newsletter</p>
                                            <p className="text-default-500">Subscribe to receive updates and news via email.</p>
                                        </div>

                                        <Checkbox
                                            isSelected={receiveNewsletter} // Newsletter value
                                            onChange={handleNewsletterChange} // Update on change
                                        >
                                            Receive newsletter
                                        </Checkbox>
                                    </div>


                                    <div className="flex flex-row gap-2 justify-between items-center">
                                        <div>
                                            <p>{t('settings.logout')}</p>
                                            <p className='text-default-500'>{t('settings.logoutDescription')}</p>
                                        </div>
                                        <Button onPress={handleLogout} disabled={isLoading}>
                                            {t('settings.logout')}
                                        </Button>
                                    </div>

                                    <div className="flex flex-row gap-2 justify-between items-center">
                                        <div>
                                            <p>{t('settings.deleteAccount')}</p>
                                            <p className='text-default-500'>{t('settings.deleteAccountDescription')}</p>
                                        </div>

                                        {confirmDelete ? (
                                            <>
                                                <p>{t('settings.enterPassword')}</p>
                                                <Input
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    disabled={isLoading}
                                                />
                                                <Button className="bg-red-400 text-gray-900" onPress={handleButtonClick} color="error" isLoading={isLoading} disabled={isLoading}>
                                                    {t('settings.confirmDelete')}
                                                </Button>
                                            </>
                                        ) : (
                                            <Button className="bg-red-400   text-gray-900" onPress={handleButtonClick} disabled={isLoading}>
                                                {t('settings.deleteAccount')}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </Tab>
                            <Tab title={
                                <div className="flex items-center space-x-1">
                                    <Microscope size={18} variant="Bold" />
                                    <span>{t('settings.mySetup')}</span>
                                </div>
                            }>
                                <SetupForm />
                            </Tab>
                        </Tabs>
                    </ModalBody>
                    <ModalFooter>
                        <Button auto flat onPress={onClose} disabled={isLoading}>{t('settings.close')}</Button>
                    </ModalFooter>
                </>
            </ModalContent>
        </Modal>
    );
};

export default SettingsModal;