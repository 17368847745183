import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Global, UserOctagon, AddSquare, FolderCloud } from 'iconsax-react';
import { useSpring, animated } from '@react-spring/web';
import UserContext from "./UserContext"; // Assurez-vous que le chemin est correct
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig";
import LogoSVG from "../assets/images/logo.svg";
import SearchContext from './SearchContext';
import { useTranslation } from 'react-i18next';

const NavBar = ({ overflowDivRef }) => {
  const { currentUser } = useContext(UserContext);
  const { resetSearch } = useContext(SearchContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [unreadCount, setUnreadCount] = useState(0);
  const isMobile = useMediaQuery(1025);
  const { t } = useTranslation();
  const iconSize = isMobile ? "24" : "20"; // Taille de l'icône pour mobile et bureau




  useEffect(() => {
    if (currentUser) {
      const notificationsCollection = collection(db, "notifications");
      const q = query(notificationsCollection, where("recipientId", "==", currentUser.uid), where("read", "==", false));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        setUnreadCount(snapshot.docs.length);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const handleNavLinkClick = (to) => {
    resetSearch(); // Réinitialiser la recherche
    if (overflowDivRef && overflowDivRef.current) {
      overflowDivRef.current.scrollTo(0, 0);
    }
    navigate(to);
  };

  const getButtonStyle = (isActive) => ({
    ...styles.button,
    ...(isActive ? styles.activeButton : {}),
    position: 'relative',
  });

  const getIconColor = (isActive) => isActive ? "hsl(var(--nextui-default-800))" : "hsl(var(--nextui-default-500))";

  const navLinks = [
    { to: "/explore", icon: Global, text: t('explore') },
    { to: "/publish", icon: AddSquare, text: t('publish') },
    //{ to: "/storage", icon: FolderCloud, text: t('storage') },
  ];



  if (currentUser && currentUser.isAdmin) {
    navLinks.push({ to: "/admin", icon: UserOctagon, text: "Admin" });
  }

  return (
    <div>
      <div style={isMobile ? null : styles.navbar}>
        <nav className="z-50" style={isMobile ? styles.mobileNav : styles.nav}>
          {!isMobile &&
            <div className="flex flex-row p-3  gap-3 cursor-pointer" onClick={() => navigate("/explore")}>
              <img src={LogoSVG} alt="Logo" className="w-6" />
              <h1 className="font-bold" >Cosmio</h1>
            </div>}
          {navLinks.map(({ to, icon, text, onClick, color, variant }) => {
            const isActive = location.pathname === to || (to === '/profile' && location.pathname.startsWith('/profile/'));
            return (
              <NavLinkWithAnimation

                key={to}
                to={to}
                icon={icon}
                iconSize={iconSize} // Passer la taille de l'icône ici
                text={!isMobile ? text : null}
                getButtonStyle={isMobile ? () => styles.mobileButton : getButtonStyle}
                getIconColor={color || getIconColor(isActive)}
                onClick={() => handleNavLinkClick(to)} // Utiliser handleNavLinkClick
                isActive={isActive}
                variant={variant}
              />
            );
          })}
        </nav>
      </div>
    </div>
  );
};

const NavLinkWithAnimation = ({ to, icon: IconComponent, text, iconSize, getButtonStyle, getIconColor, onClick, isActive, variant }) => {
  const [spring, handleClick] = useBounceAnimation();
  return (
    <Link to={to} style={getButtonStyle(isActive)} onClick={onClick}>
      <animated.div
        style={{ ...getButtonStyle(isActive), ...spring }}
        onClick={handleClick}

      >
        <IconComponent size={iconSize} color={getIconColor} variant={variant || (isActive ? "Bold" : "Linear")} />
        <p style={styles.buttonText}>{text}</p>
      </animated.div>
    </Link>
  );
};

const useBounceAnimation = () => {
  const [spring, setSpring] = useSpring(() => ({ transform: 'scale(1)' }));
  const handleClick = () => {
    setSpring({
      transform: 'scale(1.05)',
      config: { tension: 300, friction: 10 },
      onRest: () => setSpring({ transform: 'scale(1)' }),
    });
  };
  return [spring, handleClick];
};

const styles = {
  navbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0.875rem",
    height: "100%",
    borderRight: '2px solid hsl(var(--nextui-default-50))',
    backgroundColor: "hsl(var(--nextui-default-50))",
  },
  nav: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "100%",
  },
  button: {
    width: "12em",
    padding: "0.6rem",
    paddingBlock: "0.3rem",
    paddingInline: "0.4rem",
    borderRadius: "8px",
    textAlign: "left",
    textDecoration: "none",
    color: "hsl(var(--nextui-default-500))",
    border: "none",
    display: "flex",
    alignItems: "center",
    gap: "0.8rem",
    cursor: "pointer",
  },
  activeButton: {
    backgroundColor: 'hsl(var(--nextui-primary-500))',
    color: "hsl(var(--nextui-default-800))",
  },
  buttonText: {
    margin: 0,
    fontSize: "15px",
    fontWeight: "500",
  },
  link: {
    width: "100%",
    textDecoration: "none",
  },
  mobileNav: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '0.875rem 0',
    backgroundColor: "hsl(var(--nextui-default-50))",

    height: "80px",
  },
  mobileButton: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

// Media query hook to detect screen size
const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = useState(false);

  useEffect(() => {
    const updateTarget = () => {
      if (window.innerWidth < width) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    };

    window.addEventListener('resize', updateTarget);
    updateTarget();

    return () => window.removeEventListener('resize', updateTarget);
  }, [width]);

  return targetReached;
};

export default NavBar;
