// src/components/CustomChip.js

import React from 'react';
import { Chip } from '@nextui-org/react';

const CustomChip = ({ children, ...props }) => {
    return (
        <div>
            <Chip
                {...props}

                variant="faded"
                className={`text-foreground custom-chip ${props.className}`}
            >
                <p className=' z-50 ' >{children}</p>

            </Chip>
        </div>
    );
};

export default CustomChip;
