import React, { useState, useEffect, useCallback, useContext } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button, Pagination } from "@nextui-org/react";
import ImageCard from "./ImageCard";
import LoginModal from "./LoginModal";
import SearchContext from "./SearchContext";
import { ArrowDown2, SearchStatus } from "iconsax-react";
import DescriptionObject from "./DescriptionObject"

const ITEMS_PER_PAGE = 20;

function Search() {
    const { results, allData, resultsCount, searching, sortOrder, setAllData, setAllUsers, setSortOrder, setResults, setResultsCount, setSearching, groupName, setGroupName } = useContext(SearchContext);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1); // Pagination state
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMetadata, setSelectedMetadata] = useState(null); // Ajoutez cet état pour les métadonnées sélectionnées
    const { selectedOptions, setSelectedOptions } = useContext(SearchContext);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const imagesCollection = collection(db, "images");
                const usersCollection = collection(db, "users");

                const [imagesSnapshot, usersSnapshot] = await Promise.all([
                    getDocs(imagesCollection),
                    getDocs(usersCollection),
                ]);

                const imageData = imagesSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(image => image.state === true && image.publish === true); // Filtrer les images

                const userData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                setAllData(imageData);
                setAllUsers(userData);
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [setAllData, setAllUsers]);

    const extractMetadataValues = useCallback((metadata) => {
        const values = [];
        for (const [key, value] of Object.entries(metadata)) {
            //if (key === 'Description') continue; // Exclude description
            if (Array.isArray(value)) {
                value.forEach(item => values.push({ group: key, value: item }));
            } else if (typeof value === 'string') {
                values.push({ group: key, value });
            }
        }
        return values;
    }, []);

    useEffect(() => {
        const performSearch = () => {
            if (searching) {
                const filteredResults = allData.filter(data => {
                    const metadataValues = data.metadata ? extractMetadataValues(data.metadata).map(item => item.value) : [];
                    const objectValues = data.objects ? data.objects : [];
                    const allValues = [...metadataValues, ...objectValues];
                    return results.some(option =>
                        allValues.includes(option.value)
                    );
                });
                setResults(filteredResults);
                setResultsCount(filteredResults.length);
                setSearching(false);
            }
        };

        performSearch();
    }, [searching, allData, results, extractMetadataValues, setResults, setResultsCount, setSearching]);

    const handleOpenModal = (metadata) => {
        setSelectedMetadata(metadata);
        setIsModalVisible(true);
    };

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const paginatedResults = results.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    const firstResult = paginatedResults.length > 0 ? paginatedResults[0] : null;

    return (
        <div className="w-full min-h-screen">
            {loading ? (
                <div>Loading...</div>
            ) : (
                searching ? (
                    <p>Searching...</p>
                ) : (
                    results.length === 0 ? (
                        <div className="flex flex-col items-center justify-center mt-14 gap-4">
                            <SearchStatus size="70" variant="Linear" color="hsl(var(--nextui-default-400))" />
                            <p className="text-md text-default-500 text-center w-64">You can search by telescope, instrument, camera, filter, user...</p>
                        </div>
                    ) : (
                        <>

                            <DescriptionObject firstResult={firstResult} metadata={selectedOptions} groupName={groupName} />

                            <div className="flex justify-between items-center mb-2 mt-4 ">
                                <div>
                                    <p className="text-xl font-medium">{resultsCount} result(s) found</p>
                                </div>
                                <div>
                                    <Dropdown className="dark">
                                        <DropdownTrigger>
                                            <Button variant="flat">
                                                {sortOrder === "mostRecent" ? "Most Recent" : "Oldest First"}
                                                <ArrowDown2 size={16} />
                                            </Button>
                                        </DropdownTrigger>
                                        <DropdownMenu className="text-foreground" aria-label="Sort Order" onAction={(key) => setSortOrder(key)}>
                                            <DropdownItem key="mostRecent">Most Recent</DropdownItem>
                                            <DropdownItem key="oldestFirst">Oldest First</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>


                            <div>
                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
                                    {paginatedResults.map((result) => (
                                        <ImageCard key={result.id} image={result} onClick={() => handleOpenModal(result.metadata)} />
                                    ))}
                                </div>
                                <div className="flex justify-center mt-4">
                                    <Pagination
                                        variant="light"
                                        showControls
                                        total={Math.ceil(resultsCount / ITEMS_PER_PAGE)}
                                        initialPage={currentPage}
                                        onChange={(page) => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                        </>
                    )
                )
            )}
            <LoginModal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)} />
        </div>
    );
}

export default Search;

