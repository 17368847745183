import React, { useContext, useRef, useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAptabase } from '@aptabase/react';
import { Capacitor } from '@capacitor/core';
import { Helmet } from 'react-helmet';

import { InAppReview } from '@capacitor-community/in-app-review';
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Upload from "./components/Upload";
import Profile from "./components/Profile";
import Search from "./components/Search";
import EditImageDetails from "./components/EditImageDetails";
import UserContext from "./components/UserContext";
import Signup from "./components/Signup";
import Login from "./components/Login";
import Footer from "./components/Footer/Footer";
import LegalNotice from "./components/Footer/LegalNotice";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy";
import TermsOfService from "./components/Footer/TermsOfService";
import EULA from "./components/Footer/EULA"
import VersionCheck from './components/VersionCheck';
import Ranking from './components/Ranking';
import { GlobalProvider } from './components/GlobalState';
import Cloud from './components/Cloud';
import TopNavBar from './components/TopNavBar';
import ArticleDetail from './components/Footer/ArticleDetail'; // Créez ce composant pour afficher les détails d'un article
import ImageDetails from './components/ImageDetails';
import FullscreenSlideshow from "./components/FullscreenSlideshow";

import Contact from "./components/Footer/Contact";
import FAQ from "./components/Footer/FAQ";
import Admin from "./components/Admin";
import AlertSystem from "./components/AlertSystem"
import AssistantVirtuel from "./components/AssistantVirtuel";
import Blog from "./components/Footer/Blog"

function App() {
  const { currentUser } = useContext(UserContext);
  const [pageContent, setPageContent] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const overflowDivRef = useRef(null);
  const { trackEvent } = useAptabase();

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 });
  let state = location.state;

  const scrollDemoRef = useRef(null);
  const companyName = "Paul Joly";
  const companyAddress = "123 Astro Lane, Galaxy City, Universe";
  const companyPhone = "123-456-7890";
  const companyEmail = "contact@cosmio.io";
  const directorName = "Paul Joly";
  const hostingCompanyName = "Firebase Hosting (Google LLC)";
  const hostingCompanyAddress = "1600 Amphitheatre Parkway, Mountain View, CA 94043, USA";
  const thirdPartyServices = [
    { name: "Firebase Google", description: "Backend services provided by Google LLC." },
    { name: "Gemini AI", description: "AI services provided by Google LLC." },
    { name: "Astrometry Nova API", description: "Astronomical data service provided by Nova." },
    { name: "Aptabase", description: "Analytics service provided by Aptabase." }
  ];

  const hideNavBarRoutes = ["/exhibition"];
  const isNavBarHidden = hideNavBarRoutes.includes(location.pathname);

  useEffect(() => {
    // Track route changes using Aptabase
    trackEvent("page_view", { path: location.pathname });
  }, [location.pathname, trackEvent]); // Depend on location.pathname and trackEvent


  useEffect(() => {
    // Liste des routes pour lesquelles tu souhaites réinitialiser le scroll
    const routesToResetScroll = ['/search', '/publish', '/profile/:username'];

    // Vérifier si la route actuelle est dans la liste des routes spécifiques
    const shouldResetScroll = routesToResetScroll.some(route => {
      if (route.includes(':')) {
        // Pour les routes dynamiques comme /profile/:username
        const regex = new RegExp(route.replace(':username', '[a-zA-Z0-9]+'));
        return regex.test(location.pathname);
      }
      return location.pathname === route;
    });

    // Réinitialiser le scroll de la div à chaque changement de route
    if (shouldResetScroll && overflowDivRef.current) {
      overflowDivRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]); // Exécuter cet effet à chaque changement de route


  return (
    <GlobalProvider>
      <VersionCheck />
      <AlertSystem />

      <Routes>
        <Route path="/exhibition" element={<FullscreenSlideshow />} />
      </Routes>

      <div className="App" style={{ display: "flex", overflowY: 'scroll', height: '100vh' }}>


        {!isNavBarHidden && <NavBar overflowDivRef={overflowDivRef} />}
        <div className="w-full mx-auto overflow-auto main-content" ref={overflowDivRef} id="scrollDemo">
          {!isNavBarHidden && <TopNavBar />}

          <Routes>
            <Route path="/image/:imageId" element={<ImageDetails />} />
          </Routes>
          <Routes location={state?.backgroundLocation || location}>

            <Route path="/explore" element={<Home />} />
            <Route path="/profile" element={currentUser ? <Navigate to={`/profile/${currentUser.username}`} /> : <Profile />} />
            <Route path="/profile/:username" element={<Profile />} />
            <Route path="/storage" element={<Cloud />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/search" element={<Search />} />
            <Route path="/ranking" element={<Ranking />} />
            <Route path="/publish" element={<Upload />} />
            <Route path="/blog/:title" element={<ArticleDetail />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/edit/:imageId" element={<EditImageDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/admin" element={<Admin />} />

            <Route
              path="/legal-notice"
              element={
                <LegalNotice
                  companyName={companyName}
                  companyAddress={companyAddress}
                  companyPhone={companyPhone}
                  companyEmail={companyEmail}
                  directorName={directorName}
                  hostingCompanyName={hostingCompanyName}
                  hostingCompanyAddress={hostingCompanyAddress}
                  thirdPartyServices={thirdPartyServices}
                  content={pageContent}
                />
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <PrivacyPolicy
                  companyName={companyName}
                  companyEmail={companyEmail}
                />
              }
            />
            <Route
              path="/terms-of-service"
              element={
                <TermsOfService
                  companyName={companyName}
                  companyEmail={companyEmail}
                />
              }
            />
            <Route
              path="/eula"
              element={
                <EULA
                  companyName={companyName}
                  companyEmail={companyEmail}
                />
              }
            />
          </Routes>

          {/* Afficher le Footer sauf si on est sur /diaporama */}
          {!isNavBarHidden && <Footer />}
        </div>
      </div>
    </GlobalProvider>
  );
}

export default App;
