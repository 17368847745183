import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db } from "../components/Firebase/firebaseConfig";
import { Button, RadioGroup, Radio, Input, Checkbox, Divider } from "@nextui-org/react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';


const { GoogleGenerativeAI } = require('@google/generative-ai');

const FullscreenSlideshow = () => {
    const [images, setImages] = useState([]); // Stocke toutes les images récupérées
    const [currentIndex, setCurrentIndex] = useState(0); // Index de l'image en cours d'affichage
    const [title, setTitle] = useState(''); // Titre actuel de l'image
    const [nextTitle, setNextTitle] = useState(''); // Titre à venir
    const [description, setDescription] = useState(''); // Description actuelle de l'image
    const [nextDescription, setNextDescription] = useState(''); // Description à venir
    const [isSlideshowActive, setIsSlideshowActive] = useState(false); // Contrôle si le diaporama est actif ou non
    const [transitionStyle, setTransitionStyle] = useState('fade'); // Type de transition utilisé (fondu ou autre)
    const [durationInSeconds, setDurationInSeconds] = useState(10); // Durée d'affichage de chaque image
    const [browserLanguage, setBrowserLanguage] = useState('en'); // Langue du navigateur détectée
    const slideshowRef = useRef(null); // Référence pour l'élément du diaporama
    const [imageFit, setImageFit] = useState('cover'); // Mode d'ajustement de l'image ('cover' ou 'contain')
    const [shouldGenerateDescription, setShouldGenerateDescription] = useState(true); // Contrôle si la description doit être générée ou non
    const [isChildMode, setIsChildMode] = useState(false); // Mode enfant activé ou non
    const [textPosition, setTextPosition] = useState('bottom-left'); // Position du texte
    const [caption, setCaption] = useState(''); // Nouvel état pour la légende
    const [nextCaption, setNextCaption] = useState(''); // État pour la légende suivante
    const { t } = useTranslation();

    const genAI = new GoogleGenerativeAI('AIzaSyDZtKO5iGJSusGELWw9-whiRxkCRv4B1IU');
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

    // Détection de la langue du navigateur et stockage de celle-ci
    useEffect(() => {
        const detectedLanguage = navigator.language || 'en'; // Par défaut 'en' si non détectée
        setBrowserLanguage(detectedLanguage.split('-')[0]); // Stocker uniquement le code de langue (ex: 'fr' au lieu de 'fr-FR')
    }, []);

    // Fonction pour récupérer la description et le titre d'une image à partir des objets célestes
    const fetchDescriptionForImage = async (objects, isNext = false) => {
        try {
            const objectsList = objects.join(', ');
            // Utiliser le mode enfant si activé
            if (isChildMode) {
                prompt = `Generate a simple and fun title and description for kids about the celestial object(s): ${objectsList}. Use simple language and respond in ${browserLanguage}. Please provide a title and a brief description in JSON format.`;
            } else {
                prompt = `Generate a description and short title for the main celestial object(s): ${objectsList}. Please provide a title and a brief description (max 80 words) in JSON format. Respond in ${browserLanguage}.`;
            }
            const result = await model.generateContent(prompt);
            const responseText = await result.response.text();
            const cleanedResponse = responseText.replace(/```json|```/g, '').trim();
            const fetchedData = JSON.parse(cleanedResponse);

            console.log(prompt)
            if (isNext) {
                setNextTitle(fetchedData.title);
                setNextDescription(fetchedData.description);
            } else {
                setTitle(fetchedData.title);
                setDescription(fetchedData.description);
            }
        } catch (error) {
            console.error("Error fetching description for image:", error);
        }
    };

    const getTimeAgo = (timestamp) => {
        const diffInMs = Date.now() - (timestamp?.seconds * 1000);

        const units = [
            { label: 'an', millis: 31536000000 },
            { label: 'mois', millis: 2592000000 },
            { label: 'jour', millis: 86400000 },
            { label: 'heure', millis: 3600000 },
            { label: 'minute', millis: 60000 },
        ];

        for (const { label, millis } of units) {
            const diff = Math.floor(diffInMs / millis);
            if (diff > 0) return `${diff} ${label}${diff > 1 && label !== 'mois' ? 's' : ''}`;
        }

        return 'moins d\'une minute';
    };

    const fetchCaption = async (imageMetadata, isNext = false) => {
        try {
            const { username, timestamp, metadata } = imageMetadata;
            const location = metadata?.LocationName || 'unknown location';
            const timeAgo = getTimeAgo(timestamp); // Calcul du temps écoulé

            const prompt = `
                Generate a simple sentence that says this image was taken by ${username} ${timeAgo} ago at ${location} (only country and city).
                Respond with a single 'caption' field in JSON format. Respond in ${browserLanguage}.
            `;

            const result = await model.generateContent(prompt);
            const responseText = await result.response.text();
            const cleanedResponse = responseText.replace(/```json|```/g, '').trim();
            const fetchedData = JSON.parse(cleanedResponse);

            // Mettre à jour la légende actuelle ou suivante en fonction de 'isNext'
            if (isNext) {
                setNextCaption(fetchedData.caption);
            } else {
                setCaption(fetchedData.caption);
            }
        } catch (error) {
            console.error("Error fetching caption for image:", error);
        }
    };


    // Fonction pour précharger l'image suivante
    const preLoadImage = (imageUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = resolve; // Résoudre la promesse une fois que l'image est chargée
        });
    };

    // Récupération des images ayant au moins 5 likes et contenant des objets célestes
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const imagesCollection = collection(db, 'images');
                const imagesSnapshot = await getDocs(
                    query(
                        imagesCollection,
                        where('like', '>=', 10),
                        orderBy('like', 'desc') // Ordonner par nombre de likes décroissant
                    )
                );
                const imagesList = imagesSnapshot.docs
                    .map(doc => ({ ...doc.data(), objects: doc.data().objects || [] }))
                    .filter(image => image.objects && image.objects.length > 0); // Ne garder que les images avec des objets

                setImages(imagesList); // Stocker la liste des images
            } catch (error) {
                console.error('Erreur lors de la récupération des images :', error);
            }
        };

        fetchImages(); // Exécuter la récupération des images
    }, []);

    // Précharger la prochaine image et description lorsque le diaporama est actif
    useEffect(() => {
        if (isSlideshowActive) {
            const preloadNextSlide = async () => {
                const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;

                if (images[nextIndex]?.imageUrl) {
                    await preLoadImage(images[nextIndex].imageUrl); // Précharge l'image suivante
                    await fetchDescriptionForImage(images[nextIndex].objects, true); // Précharge la description suivante
                    await fetchCaption(images[nextIndex], true); // Précharge la légende suivante
                }
            };

            preloadNextSlide();
        }
    }, [currentIndex, images, isSlideshowActive]);


    useEffect(() => {
        if (isSlideshowActive) {
            const interval = setInterval(() => {
                const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;

                // Mettre à jour l'image, la description, et la légende avec celles qui ont été préchargées
                setTitle(nextTitle);
                setDescription(nextDescription);
                setCaption(nextCaption); // Met à jour la légende

                setCurrentIndex(nextIndex);
            }, durationInSeconds * 1000);

            return () => clearInterval(interval);
        }
    }, [nextTitle, nextDescription, nextCaption, isSlideshowActive, currentIndex, durationInSeconds]);


    const handleFullscreen = () => {
        if (slideshowRef.current.requestFullscreen) {
            slideshowRef.current.requestFullscreen(); // Plein écran pour les navigateurs modernes
        } else if (slideshowRef.current.mozRequestFullScreen) { // Firefox
            slideshowRef.current.mozRequestFullScreen();
        } else if (slideshowRef.current.webkitRequestFullscreen) { // Chrome, Safari et Opera
            slideshowRef.current.webkitRequestFullscreen();
        } else if (slideshowRef.current.msRequestFullscreen) { // IE/Edge
            slideshowRef.current.msRequestFullscreen();
        }
    };

    const startSlideshow = () => {
        setIsSlideshowActive(true); // Activer le diaporama
        handleFullscreen(); // Passer en mode plein écran

        // Précharger la description pour la première image
        const currentImage = images[currentIndex];
        if (currentImage) {
            fetchCaption(currentImage); // Appel de la fonction fetchCaption ici

            fetchDescriptionForImage(currentImage.objects);
        }
    };

    const handleClose = () => {
        setIsSlideshowActive(false); // Désactiver le diaporama
        if (document.fullscreenElement) {
            document.exitFullscreen(); // Sortir du mode plein écran
        }
    };

    const getTextPositionClass = () => {
        switch (textPosition) {
            case 'top-left':
                return 'top-4 left-4';
            case 'bottom-left':
                return 'bottom-4 left-4';
            default:
                return 'bottom-4 left-4';
        }
    };




    return (
        <div ref={slideshowRef} className="fixed inset-0 flex items-center justify-center z-50">
        <img onClick={handleClose} className='transition duration-150 top-4 left-4 absolute w-6 z-50 hover:opacity-100 opacity-40 cursor-pointer' src={Logo} alt="Logo" />
        {!isSlideshowActive ? (
            <div className='flex flex-col gap-6 sm:w-1/2 w-full bg-default-50 p-8 rounded-lg'>
                <div className='flex flex-col gap-2'>
                    <h1 className="text-3xl font-bold">{t('exhibitionMode')}</h1>
                    <p className="text-lg text-default-600">
                        {t('exhibitionDescription')}
                    </p>
                </div>

                <Divider />

                <div className='flex flex-col gap-8'>
                    <h1 className="text-xl font-bold">{t('configuration')}</h1>

                    <div>
                        <Checkbox
                            isSelected={isChildMode}
                            onValueChange={setIsChildMode}
                        >
                            {t('childMode')}
                        </Checkbox>
                        <p className='text-default-500'>{t('childModeDescription')}</p>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        <RadioGroup
                            label={t('selectTransition')}
                            value={transitionStyle}
                            onValueChange={setTransitionStyle}
                        >
                            <Radio value="fade">{t('fade')}</Radio>
                            <Radio value="none">{t('none')}</Radio>
                        </RadioGroup>

                        <RadioGroup
                            label={t('selectImageFit')}
                            value={imageFit}
                            onValueChange={setImageFit}
                        >
                            <Radio value="cover">{t('cover')}</Radio>
                            <Radio value="contain">{t('contain')}</Radio>
                        </RadioGroup>

                        <RadioGroup
                            label={t('selectTextPosition')}
                            value={textPosition}
                            onValueChange={setTextPosition}
                        >
                            <Radio value="top-left">{t('topLeft')}</Radio>
                            <Radio value="bottom-left">{t('bottomLeft')}</Radio>
                        </RadioGroup>
                    </div>

                    <Input
                        label={t('displayDuration')}
                        type="number"
                        labelPlacement='outside'
                        value={durationInSeconds}
                        onChange={(e) => setDurationInSeconds(Number(e.target.value))}
                        min={1}
                        endContent={
                            <div className="pointer-events-none flex items-center">
                                <span className="text-default-400 text-small">{t('seconds')}</span>
                            </div>
                        }
                        className='max-w-40'
                    />
                </div>

                <Divider />

                <Button color='primary' className='w-full' size='lg' onPress={startSlideshow}>
                    {t('launchExperience')}
                </Button>
            </div>
        ) : (
            // Diaporama avec transition
            <div className="relative w-full h-full flex items-center justify-center">
                {images.length > 0 && (
                    <SwitchTransition>
                        <CSSTransition
                            key={currentIndex}
                            timeout={1000}
                            classNames="fade"
                        >
                            <div className="w-full h-full relative">
                                <img
                                    src={images[currentIndex].imageUrl}
                                    alt={`Slide ${currentIndex + 1}`}
                                    className={`w-full h-full slow-zoom ${imageFit === 'cover' ? 'object-cover' : 'object-contain'}`}
                                />

                                {shouldGenerateDescription && (title || description || caption) && (
                                    <div className={`absolute p-6 text-white flex flex-col gap-2 w-full ${getTextPositionClass()}`}>
                                        <h1 className="text-5xl font-bold mb-2 w-9/12">{title}</h1>
                                        <div className='flex flex-col gap-2 w-7/12'>
                                            <p className='text-2xl'>{description}</p>
                                            <p className='text-lg text-default-600'>{caption}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                )}
            </div>
        )}
    </div>
    );
};

export default FullscreenSlideshow;
