import React, { useEffect, useState } from 'react';
import { db } from "../Firebase/firebaseConfig";
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@nextui-org/react';

const Blog = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true); // État pour le chargement
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const articlesCollection = collection(db, 'articles');
                const articlesSnapshot = await getDocs(articlesCollection);
                const articlesList = articlesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setArticles(articlesList);
            } catch (error) {
                console.error("Erreur lors du chargement des articles :", error);
            } finally {
                setLoading(false); // Mettre à jour l'état de chargement
            }
        };

        fetchArticles();
    }, []);

    const handleArticleClick = (title) => {
        const slugifiedTitle = title.replace(/\s+/g, '-').toLowerCase(); // Crée un slug à partir du titre
        navigate(`/blog/${slugifiedTitle}`); // Redirige avec le titre
    };

    // Fonction pour formater la date
    const formatDate = (date) => {
        return date.toLocaleDateString('en-EN', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    return (
        <div className='min-h-screen'>
            <h1 className='text-3xl font-bold'>Blog</h1>

            <div className='mt-4 flex flex-col gap-4'>
                {loading ? (
                    // Utilisation de map pour générer plusieurs Skeletons
                    Array(4).fill(0).map((_, index) => (
                        <div key={index} >
                            <Skeleton className="rounded-lg bg-default-100">
                                <div className="h-40 rounded-lg "></div>
                            </Skeleton>
                        </div>
                    ))
                ) : articles.length > 0 ? (
                    articles.map(article => (
                        <div
                            key={article.id}
                            className='p-6 rounded-lg bg-default-100 cursor-pointer'
                            onClick={() => handleArticleClick(article.title)} // Passer uniquement le titre
                        >
                            <div className='flex flex-col sm:flex-row gap-6'>
                                <div>
                                    <img
                                        src={article.firstImage}
                                        alt={article.title}
                                        className='m-auto object-cover max-w-64 rounded mb-2'
                                    />
                                </div>

                                <div>
                                    <h2 className='text-xl font-semibold my-2'>{article.title}</h2>

                                    <div className='flex flex-col gap-2' >
                                        <p className='text-default-700 line-clamp-3'>{article.description}</p>
                                        <p className='text-sm text-default-500'>
                                            {formatDate(article.createdAt.toDate())}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Aucun article disponible.</p>
                )}
            </div>
        </div>
    );
};

export default Blog;
