import React, { createContext, useState } from 'react';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [results, setResults] = useState([]);
    const [allData, setAllData] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [searching, setSearching] = useState(false);
    const [sortOrder, setSortOrder] = useState("mostRecent");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [groupName, setGroupName] = useState(""); // Ajoutez cet état pour le nom du groupe

    const resetSearch = () => {
        setResults([]);
        setResultsCount(0);
        setSearching(false);
        setSortOrder("mostRecent");
        setSelectedOptions([]); // Réinitialiser les tags
        setGroupName(""); // Réinitialiser le nom du groupe
    };

    
    return (
        <SearchContext.Provider value={{
            results,
            setResults,
            allData,
            setAllData,
            allUsers,
            setAllUsers,
            resultsCount,
            setResultsCount,
            searching,
            setSearching,
            sortOrder,
            setSortOrder,
            selectedOptions,
            setSelectedOptions,
            groupName,
            setGroupName,
            resetSearch
        }}>
            {children}
        </SearchContext.Provider>
    );
};

export default SearchContext;
