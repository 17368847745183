import React, { useContext, useState, useEffect, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, addDoc, Timestamp, onSnapshot, doc, deleteDoc, updateDoc, getDoc, GeoPoint, setDoc, where, query } from "firebase/firestore";
import { storage, db } from "./Firebase/firebaseConfig";
import UserContext from "./UserContext";
import { Button, Progress, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Skeleton, Link, Tabs, Tab, Chip } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import ImageCard from "./ImageCard";
import { GalleryAdd } from "iconsax-react";

import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import SuccessSVG from "../assets/images/success.svg";
import LoginModal from "./LoginModal";
import UploadForm from './UploadForm';
import { useTranslation } from 'react-i18next';

const libraries = ["places"];
const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/tiff', 'image/webp'];
const MAX_FILE_SIZE_MB = 50;
const RESULT_LIMIT = 10;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-100))',
        '&:hover': {
            borderColor: 'transparent',
        },
        borderColor: 'transparent',
        borderRadius: '12px',
        height: 'fit-content',
        minHeight: '40px',
        boxShadow: 'transparent',
        cursor: state.isFocused ? 'text' : 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: 'hsl(var(--nextui-default-900))',
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-100))',
        color: 'hsl(var(--nextui-default-900))',
        borderRadius: '8px',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'hsl(var(--nextui-default-200))' : 'hsl(var(--nextui-default-100))',
        color: 'hsl(var(--nextui-default-900))',
        fontSize: '0.875rem',
        cursor: 'pointer',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-300))',
        borderRadius: '24px',
        paddingInlineStart: '8px',
        paddingBlock: '2px',
        color: 'hsl(var(--nextui-default-900))',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
        paddingInline: '6px',
        marginInlineStart: '8px',
        ':hover': {
            backgroundColor: 'hsl(var(--nextui-default-900))',
            color: 'hsl(var(--nextui-default-200))',
        },
        borderRadius: '24px',
    }),
    input: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
        fontSize: '0.875rem',
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '0.875rem',
        color: 'hsl(var(--nextui-default-500))',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-700))',
    }),
};

const fetchDefaultOptions = async (collectionName) => {
    const docRef = doc(db, 'system', collectionName);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (collectionName in data) {
            return data[collectionName].map(value => ({
                value: value,
                label: value
            })).slice(0, 10);
        } else {
            console.log(`No such field ${collectionName} in document ${collectionName}!`);
            return [];
        }
    } else {
        console.log(`No such document ${collectionName}!`);
        return [];
    }
};

const loadOptions = async (inputValue, callback, collection) => {
    const docRef = doc(db, 'system', collection);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (collection in data) {
            const options = data[collection]
                .filter(item => item.toLowerCase().includes(inputValue.toLowerCase()))
                .map(item => ({
                    value: item,
                    label: item
                }));
            callback(options.slice(0, RESULT_LIMIT));
        } else {
            console.log(`No such field ${collection} in document ${collection}!`);
            callback([]);
        }
    } else {
        console.log(`No such document for ${collection}!`);
        callback([]);
    }
};

const handleCreateOption = async (inputValue, collection) => {
    const newOption = inputValue;
    const newDocName = `Generate_${inputValue}`;
    const newDocRef = doc(db, 'system', `Generate_${collection}`);
    const docSnapshot = await getDoc(newDocRef);
    if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        data[newDocName] = newOption;
        await setDoc(newDocRef, data);
    } else {
        const newData = { [newDocName]: newOption };
        await setDoc(newDocRef, newData);
    }
    return { value: newOption, label: newOption };
};

function Upload() {
    const navigate = useNavigate();
    const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyBOxGeBmlLVcDXCh6O-RmteNwwXwEDXqyo", libraries });
    const { currentUser } = useContext(UserContext);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [imageUrl, setImageUrl] = useState(null);
    const [objects, setObjects] = useState([]);
    const [uploadTask, setUploadTask] = useState(null);
    const [realtimeObjects, setRealtimeObjects] = useState([]);
    const [imageDocId, setImageDocId] = useState(null);
    const [loading, setLoading] = useState(null);
    const [metadata, setMetadata] = useState({
        Object: [],
        Telescope: [],
        Camera: [],
        Software: [],
        Accessory: [],
        Filter: [],
        Mount: [],
        Location: [],
        Description: "",
    });
    const [locationName, setLocationName] = useState("");
    const [defaultOptions, setDefaultOptions] = useState({});
    const [step, setStep] = useState(1);
    const [isPublished, setIsPublished] = useState(false);
    const [location, setLocation] = useState("");
    const searchBoxRef = useRef(null);
    const [processingState, setProcessingState] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [descriptionError, setDescriptionError] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLeaveConfirmVisible, setIsLeaveConfirmVisible] = useState(false);
    const [hasPendingChanges, setHasPendingChanges] = useState(false);
    const [draftImages, setDraftImages] = useState([]);
    const [brightness, setBrightness] = useState(0);
    const [contrast, setContrast] = useState(0);
    const [saturation, setSaturation] = useState(0);

    const [initialBrightness, setInitialBrightness] = useState(0);
    const [initialContrast, setInitialContrast] = useState(0);
    const [initialSaturation, setInitialSaturation] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        if (currentUser) {
            const q = query(collection(db, "images"), where("author", "==", currentUser.uid), where("publish", "==", false));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const images = [];
                querySnapshot.forEach((doc) => {
                    images.push({ ...doc.data(), id: doc.id });
                });
                setDraftImages(images);
            });
            return () => unsubscribe();
        }
    }, []);



    useEffect(() => {
        if (imageDocId) {
            const imageDocRef = doc(db, "images", imageDocId);

            const unsubscribe = onSnapshot(imageDocRef, (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    if (data.objects) {
                        setObjects(data.objects.map(obj => ({ value: obj, label: obj })));
                        setRealtimeObjects(data.objects);
                    }
                    setProcessingState(data.state === "processing");
                }
            });

            return () => unsubscribe();
        }
    }, [imageDocId]);


    useEffect(() => {
        if (currentUser) {
            const fetchOptions = async () => {
                const optionCollections = ['telescope', 'camera', 'software', 'filter', 'accessory', 'mount', 'object'];
                const defaultOptions = {};
                for (const collection of optionCollections) {
                    await fetchDefaultOptions(collection, (options) => {
                        defaultOptions[collection] = options;
                    });
                }
                setDefaultOptions(defaultOptions);
            };
            fetchOptions();
        }
    }, []);

    useEffect(() => {
        setObjects(realtimeObjects.map(obj => ({ value: obj, label: obj })));
    }, [realtimeObjects]);



    const handleBrightnessChange = (value) => {
        setBrightness(value);
    };

    const handleContrastChange = (value) => {
        setContrast(value);
    };

    const handleSaturationChange = (value) => {
        setSaturation(value);
    };

    const resetTransformations = () => {
        setBrightness(0);
        setContrast(0);
        setSaturation(0);
    };



    const getImageStyle = () => {
        const brightnessValue = 1 + brightness;
        const contrastValue = 1 + contrast;
        const saturationValue = 1 + saturation;

        return {
            filter: `brightness(${brightnessValue}) contrast(${contrastValue}) saturate(${saturationValue})`,
        };
    };


    const applyTransformations = (file, brightness, contrast, saturation) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');

                    ctx.filter = `brightness(${1 + brightness}) contrast(${1 + contrast}) saturate(${1 + saturation})`;
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    canvas.toBlob((blob) => {
                        if (blob) resolve(blob);
                        else reject(new Error('Canvas toBlob conversion failed.'));
                    }, 'image/jpeg'); // Convert to JPEG
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        });
    };


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileSizeMB = selectedFile.size / (1024 * 1024);
            if (!acceptedFileTypes.includes(selectedFile.type)) {
                setErrorMessage("Invalid file type. Please select an image file (jpeg, png, tiff).");
            } else if (fileSizeMB > MAX_FILE_SIZE_MB) {
                setErrorMessage("File size exceeds 50 MB. Please select a smaller file.");
            } else {
                setFile(selectedFile);
                setInitialBrightness(0);
                setInitialContrast(0);
                setInitialSaturation(0);
                handleUpload(selectedFile);
                setStep(2);
                setHasPendingChanges(true);
            }
        }
    };


    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const selectedFile = e.dataTransfer.files[0];
        if (selectedFile) {
            const fileSizeMB = selectedFile.size / (1024 * 1024);
            if (!acceptedFileTypes.includes(selectedFile.type)) {
                setErrorMessage("Invalid file type. Please select an image file (jpeg, png, tiff).");
            } else if (fileSizeMB > MAX_FILE_SIZE_MB) {
                setErrorMessage("File size exceeds 50 MB. Please select a smaller file.");
            } else {
                setFile(selectedFile);
                handleUpload(selectedFile);
                setStep(2);
                setHasPendingChanges(true);
            }
        }
    };

    const handleUpload = async (file) => {
        if (!file) {
            console.log("No file selected for upload");
            return;
        }

        setIsImageLoading(true);

        try {
            // Applique les transformations à l'image
            const transformedFile = await applyTransformations(file, brightness, contrast, saturation);

            const compressedFile = await imageCompression(transformedFile, {
                maxSizeMB: 25,
                useWebWorker: true,
                maxWidthOrHeight: 1920,
            });

            const uniqueId = uuidv4();
            const storageRef = ref(storage, `images/${uniqueId}_${compressedFile.name || file.name}`);
            const uploadTaskInstance = uploadBytesResumable(storageRef, compressedFile);
            setUploadTask(uploadTaskInstance);

            uploadTaskInstance.on(
                "state_changed",
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                    console.log(`Upload is ${progress}% done`);
                },
                (error) => {
                    console.error("Upload failed", error);
                    setErrorMessage("Upload failed");
                },
                async () => {
                    console.log("Upload completed successfully!");
                    setUploadProgress(0);

                    const downloadUrl = await getDownloadURL(uploadTaskInstance.snapshot.ref);
                    setImageUrl(downloadUrl);
                    setIsImageLoading(false);

                    try {
                        const imagesCollectionRef = collection(db, "images");

                        const docRef = await addDoc(imagesCollectionRef, {
                            imageUrl: downloadUrl,
                            author: currentUser.uid,
                            username: currentUser.username,
                            timestamp: Timestamp.now(),
                            objects: objects.map(obj => obj.value),
                            metadata: Object.keys(metadata).reduce((acc, key) => {
                                acc[key] = Array.isArray(metadata[key]) ? metadata[key].map(item => item.value || item) : metadata[key];
                                return acc;
                            }, {}),
                            like: 0,
                            publish: false,
                            state: "processing"
                        });

                        setImageDocId(docRef.id);
                        console.log("Image document added successfully with the new image link");
                    } catch (error) {
                        console.error("Failed to add image document", error);
                    }
                }
            );
        } catch (error) {
            console.error("Image transformation or upload failed", error);
            setIsImageLoading(false);
        }
    };


    const handleCancelUpload = async () => {
        if (uploadTask) {
            uploadTask.cancel();
            console.log("Upload cancelled");
        }
        if (imageDocId) {
            try {
                const imageDocRef = doc(db, "images", imageDocId);
                const imageDocSnapshot = await getDoc(imageDocRef);
                if (imageDocSnapshot.exists()) {
                    const imagePath = imageDocSnapshot.data().imageUrl;
                    if (imagePath) {
                        const storageRef = ref(storage, imagePath);
                        await deleteObject(storageRef);
                        console.log("Image deleted from storage successfully");
                    } else {
                        console.error("Image path is missing");
                    }

                    const annotatedImagePath = imageDocSnapshot.data().annotatedImageUrl;
                    if (annotatedImagePath) {
                        const annotatedStorageRef = ref(storage, annotatedImagePath);
                        await deleteObject(annotatedStorageRef);
                        console.log("Annotated image deleted from storage successfully");
                    } else {
                        console.error("Annotated image path is missing");
                    }
                } else {
                    console.error("Document does not exist");
                }

                await deleteDoc(imageDocRef);
                console.log("Image document deleted successfully");

                setImageDocId(null);
                setRealtimeObjects([]);
            } catch (error) {
                console.error("Error deleting document or image", error);
            }
        }
        setImageUrl(null);
        setUploadProgress(0);
        setStep(1);
        setHasPendingChanges(false);
        setMetadata([]);
    };

    const handlePostImage = async () => {
        if (imageDocId) {

            setLoading(true);

            try {
                const imageDocRef = doc(db, "images", imageDocId);

                const today = new Date();
                const observationDate = metadata.ObservationDate || {};
                const year = observationDate.year ?? today.getFullYear();
                const month = (observationDate.month ?? (today.getMonth() + 1)) - 1;
                const day = observationDate.day ?? today.getDate();

                const observationTimestamp = new Date(year, month, day);
                if (isNaN(observationTimestamp.getTime())) {
                    throw new Error("Invalid Observation Date");
                }

                // Vérifier si les paramètres ont changé
                if (brightness !== initialBrightness || contrast !== initialContrast || saturation !== initialSaturation) {
                    // Applique les transformations à l'image
                    const selectedFile = file;
                    const transformedFile = await applyTransformations(selectedFile, brightness, contrast, saturation);

                    const compressedFile = await imageCompression(transformedFile, {
                        maxSizeMB: 25,
                        useWebWorker: true,
                        maxWidthOrHeight: 1980,
                    });

                    const uniqueId = uuidv4();
                    const storageRef = ref(storage, `images/${uniqueId}_${compressedFile.name || file.name}`);
                    const uploadTaskInstance = uploadBytesResumable(storageRef, compressedFile);
                    await uploadTaskInstance.then();

                    const downloadUrl = await getDownloadURL(uploadTaskInstance.snapshot.ref);

                    await updateDoc(imageDocRef, {
                        imageUrl: downloadUrl,
                        publish: true,
                        objects: objects.map(obj => obj.value),
                        metadata: {
                            ...Object.keys(metadata).reduce((acc, key) => {
                                acc[key] = Array.isArray(metadata[key]) ? metadata[key].map(item => item.value || item) : metadata[key];
                                return acc;
                            }, {}),
                            LocationName: locationName,
                            ObservationDate: Timestamp.fromDate(observationTimestamp)
                        }
                    }, { merge: true });

                    console.log("Image published successfully");
                    setIsPublished(true);
                    onOpen();
                    setHasPendingChanges(false);
                } else {
                    await updateDoc(imageDocRef, {
                        publish: true,
                        objects: objects.map(obj => obj.value),
                        metadata: {
                            ...Object.keys(metadata).reduce((acc, key) => {
                                acc[key] = Array.isArray(metadata[key]) ? metadata[key].map(item => item.value || item) : metadata[key];
                                return acc;
                            }, {}),
                            LocationName: locationName,
                            ObservationDate: Timestamp.fromDate(observationTimestamp)
                        }
                    }, { merge: true });

                    console.log("Image published successfully without changes");
                    setIsPublished(true);
                    onOpen();
                    setHasPendingChanges(false);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Failed to publish image", error);
            }
        }
    };



    const handlePlaceChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places && places.length > 0) {
            const place = places[0];
            const location = place.geometry.location;
            setMetadata(prevMetadata => ({
                ...prevMetadata,
                Location: new GeoPoint(location.lat(), location.lng())
            }));
            setLocationName(place.formatted_address);
            setLocation(place.formatted_address);
        }
    };

    useEffect(() => {
        if (!currentUser) {
            setIsModalVisible(true);
        }
    }, [currentUser]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (hasPendingChanges) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden' && hasPendingChanges) {
                setIsLeaveConfirmVisible(true);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [hasPendingChanges]);

    return (
        <div className="min-h-screen ">
            {currentUser ? (
                <>
                    <h1 className="text-3xl font-bold mb-2">{t('publishTab.title')}</h1>
                    {step === 1 && (
                        <Tabs disabledKeys={draftImages.length === 0 ? ["draft"] : []} variant="underlined">
                            <Tab className="h-full" key="upload" title={t('publishTab.upload')}>
                                <div className="flex upload-container bg-default-50 h-1/2"
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >
                                    <div>
                                        <div className="file-input-wrapper">
                                            <input
                                                type="file"
                                                id="file-upload"
                                                onChange={handleFileChange}
                                                className="file-input"
                                            />
                                            <div className="flex flex-col items-center justify-center gap-6">
                                                <GalleryAdd size="80" variant="Linear" color="hsl(var(--nextui-default-500))" />
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex items-center justify-center gap-2">
                                                        <label htmlFor="file-upload">
                                                            <Link className="text-foreground cursor-pointer text-md" underline="always">
                                                                {t('publishTab.chooseFile')}
                                                            </Link>
                                                        </label>
                                                        <p>{t('publishTab.dragDrop')}</p>
                                                    </div>
                                                    <p className="text-md text-default-400">
                                                        {t('publishTab.allowedFormats')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {errorMessage && (
                                            <div className="error-message">
                                                {errorMessage}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Tab>
                            <Tab
                                key="draft"
                                title={
                                    <div className="flex flex-row gap-2 items-center">
                                        <p>{t('publishTab.draft')}</p>
                                        {draftImages.length === 0 ? [] : [<Chip className="p-1" color="warning" size="sm" variant="bordered">{draftImages.length}</Chip>]}
                                    </div>
                                }
                            >
                                <>
                                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                        {draftImages.map((image) => (
                                            <ImageCard key={image.id} image={image} />
                                        ))}
                                    </div>
                                </>
                            </Tab>
                        </Tabs>
                    )}
                    {step === 2 && (
                        <div className="flex flex-col  w-full gap-6">
                            <div className="container-image-preview">
                                <div className="image-preview w-full basis-4/6">
                                    {isImageLoading ? (
                                        <Skeleton className="bg-default-100 rounded-lg">
                                            <div className="h-[80vh] rounded-lg"></div>
                                        </Skeleton>
                                    ) : (
                                        <img src={imageUrl} alt="Uploaded" style={getImageStyle()} className="responsive-img" />
                                    )}
                                    {uploadProgress > 0 && (
                                        <Progress
                                            value={uploadProgress}
                                            shadow
                                            color="primary"
                                            status="primary"
                                            striped
                                            size="sm"
                                        />
                                    )}
                                </div>
                                <UploadForm
                                    isLoaded={isLoaded}
                                    searchBoxRef={searchBoxRef}
                                    locationName={locationName}
                                    setLocationName={setLocationName}
                                    isImageLoading={isImageLoading}
                                    handlePlaceChanged={handlePlaceChanged}
                                    metadata={metadata}
                                    setMetadata={setMetadata}
                                    customStyles={customStyles}
                                    objects={objects}
                                    setObjects={setObjects}
                                    handleCreateOption={handleCreateOption}
                                    loadOptions={loadOptions}
                                    handleCancelUpload={handleCancelUpload}
                                    handlePostImage={handlePostImage}
                                    processingState={processingState}
                                    descriptionError={descriptionError}
                                    setDescriptionError={setDescriptionError}
                                    brightness={brightness}
                                    contrast={contrast}
                                    saturation={saturation}
                                    handleBrightnessChange={handleBrightnessChange}
                                    handleContrastChange={handleContrastChange}
                                    handleSaturationChange={handleSaturationChange}
                                    resetTransformations={resetTransformations}
                                    loading={loading}
                                    realtimeObjects={realtimeObjects}

                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <></>
            )}

            <Modal
                placement="center"
                hideCloseButton
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                className="purple-dark text-foreground text-center"
                isDismissable={false}
            >
                <ModalContent>
                    <img src={SuccessSVG} alt="Search illustration" className="w-56 mt-8 m-auto" />
                    <ModalHeader className="pt-0 m-auto">Image Submission</ModalHeader>
                    <ModalBody>
                        <p>{t('publishTab.submissionMessage')}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" className="w-full" onClick={() => navigate("/profile")}>
                            {t('publishTab.ok')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <LoginModal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)} />

            <style jsx>{`
                .upload-container {
                    border: 2px dashed hsl(var(--nextui-default-300));
                    border-radius: 8px;
                    padding: 32px;
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }

                .file-input-wrapper {
                    position: relative;
                    display: inline-block;
                    margin-bottom: 1rem;
                    width: 100%;
                    height: 100%;
                }

                .file-input {
                    display: none;
                }

                .file-input-label {
                    display: inline-block;
                    padding: 8px 12px;
                    cursor: pointer;
                    background-color: hsl(var(--nextui-default-500));
                    color: white;
                    border-radius: 8px;
                    transition: background-color 0.3s ease;
                }

                .file-input-label:hover {
                    background-color: #005bb5;
                }

                .drag-drop-text {
                    margin-top: 16px;
                    font-size: 1.25rem;
                    color: hsl(var(--nextui-default-500));
                }

                .image-preview {
                    display:flex;
                    border-radius: 8px;
                    height: 80h;
                    border: 1px solid hsl(var(--nextui-default-100));
                    flex-direction: column;
                }

                .error-message {
                    color: hsl(var(--nextui-error));
                }

                .responsive-img {
                    width: auto;
                    height: auto;
                    margin: auto;
                    max-height: 80vh;
                }

                .location-input {
                    width: 100%;
                    border-radius: 12px;
                    padding: 10px;
                    background-color: hsl(var(--nextui-default-100));
                    font-size: 0.875rem;
                    color: hsl(var(--nextui-default-900));
                    height: 44px;
                }

                .chip {
                    margin-bottom: 10px;
                }

                .label-async {
                    color: hsl(var(--nextui-default-400));
                    font-size: 0.875rem;
                    font-weight: normal;
                }

                .tab-content {
                    display:flex;
                    flex-direction: column;
                    gap: 12px;
                    border-radius: 8px;
                }
            `}</style>
        </div>
    );
}

export default Upload;
