// ImageCard.js
import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardBody, Skeleton, Chip, Tooltip, Button } from "@nextui-org/react";
import { formatDistanceToNow } from 'date-fns';
import LikeButton from "./LikeButton"; // Ensure the path is correct
import CustomChip from '../components/CustomChip'; // Import the custom component
import { Share } from '@capacitor/share';
import UserContext from "../components/UserContext"; // Ensure the path is correct
import { InfoCircle, Message, Scanning, ExportCurve } from "iconsax-react";
import { doc, updateDoc } from 'firebase/firestore';
import { db } from './Firebase/firebaseConfig'; // Ensure the path to your firebase config is correct
import ImageDetails from "./ImageDetails"; // Import the ImageDetails component
import { useGlobalState, useGlobalDispatch } from './GlobalState'; // Import global state hooks
import { useAptabase } from '@aptabase/react';
import { useTranslation } from 'react-i18next';
import { collection, query, where, getDocs } from 'firebase/firestore';


const ImageCard = ({ image }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isCardLoaded, setIsCardLoaded] = useState(false);
    const { currentUser } = useContext(UserContext); // Get the current user
    const [isPublished, setIsPublished] = useState(image.publish); // Local publish state
    const [State, setState] = useState(image.state); // Local publish state
    const navigate = useNavigate();
    const location = useLocation();
    const { state, fetchUserImages } = useGlobalState();
    const dispatch = useGlobalDispatch();
    const [commentCount, setCommentCount] = useState(0);
    const { trackEvent } = useAptabase();
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false); // Nouvel état pour mobile

    // Détecter si l'utilisateur est sur un appareil mobile
    useEffect(() => {
        const checkIfMobile = () => {
            const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            setIsMobile(isMobileDevice);
        };
        checkIfMobile();
    }, []);

    useEffect(() => {
        const fetchCommentAndReplyCount = async () => {
            try {
                // Compter les commentaires principaux
                const commentsRef = collection(db, 'comments');
                const commentsQuery = query(commentsRef, where('imageId', '==', image.id));
                const commentsSnapshot = await getDocs(commentsQuery);
                const commentsCount = commentsSnapshot.size;

                // Compter les réponses
                const repliesRef = collection(db, 'replies');
                const repliesQuery = query(repliesRef, where('commentId', 'in', commentsSnapshot.docs.map(doc => doc.id)));
                const repliesSnapshot = await getDocs(repliesQuery);
                const repliesCount = repliesSnapshot.size;

                // Additionner les deux
                setCommentCount(commentsCount + repliesCount);
            } catch (error) {
                console.error('Error fetching comment and reply count:', error);
            }
        };
        fetchCommentAndReplyCount();
    }, [image.id]);


    useEffect(() => {
        if (!state.userImagesFetched) {
            fetchUserImages();
        }
    }, [fetchUserImages, state.userImagesFetched]);

    useEffect(() => {
        if (isImageLoaded) {
            setIsCardLoaded(true);
        }
    }, [isImageLoaded]);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    const formatRelativeTime = (timestamp) => {
        if (!timestamp || !timestamp.seconds) {
            return 'Unknown time';
        }
        const date = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to JS date
        return formatDistanceToNow(date, { addSuffix: false });
    };

    const handlePublish = async () => {
        try {
            // Reference to the image document
            const imageDocRef = doc(db, 'images', image.id);

            // Update the publish status in Firestore
            await updateDoc(imageDocRef, {
                publish: true,
            });

            // Update the local state
            image.publish = true;
            setIsPublished(true);
            console.log(`Image with ID: ${image.id} published successfully`);

            // Optional: Update global state if needed
            dispatch({ type: 'SET_USER_IMAGES', payload: state.userImages.map(img => img.id === image.id ? { ...img, publish: true } : img) });

        } catch (error) {
            console.error('Error publishing the image:', error);
        }
    };

    const handleEditDraft = () => {
        navigate(`/edit/${image.id}`);
    };

    const openModal = () => {
   
        // Save the current scroll position

        navigate(`/image/${image.id}`, { state: { backgroundLocation: location } });

    };

    useLayoutEffect(() => {
        // Restore the scroll position if available
        if (location.state && location.state.scrollPosition !== undefined) {
            window.scrollTo(0, location.state.scrollPosition);
        }
    }, [location.state]);





    // Fonction pour partager l'image
    const handleShare = async () => {
        await Share.share({
            title: 'Check out this astronomy image!',
            text: 'Here is a cool astronomy image I found on Cosmio!',
            url: `${window.location.origin}/image/${image.id}`,
            dialogTitle: 'Share this image',
        });
    };


    const handleCopyUrl = async () => {
        try {
            const imageUrl = `${window.location.origin}/image/${image.id}`; // Génère l'URL de l'image
            await navigator.clipboard.writeText(imageUrl); // Copie l'URL dans le presse-papier
            alert('URL copied to clipboard'); // Affiche un message de succès
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <>
            <Card className="relative transform transition duration-300 border border-stone-800 flex flex-col  w-full z-0 bg-default-100 dark">
                <Skeleton className="rounded-lg flex flex-col h-full bg-default-50" isLoaded={isCardLoaded}>
                    <div className="relative w-full h-64" >

                        <img
                            onClick={openModal}
                            src={image.imageUrl}
                            alt={image.name}
                            className={`w-full object-cover ${isImageLoaded ? 'block' : 'hidden'} cursor-pointer`}
                            onLoad={handleImageLoad}
                            style={{ height: "100%" }}
                        />
                        {isPublished === false && (
                            <div className="absolute top-2 right-2">
                                <Tooltip className="dark" content="This image is a draft and is not yet published">
                                    <Chip startContent={<InfoCircle size={18} variant='Bold' />} color="warning">{t("publishTab.draft")}</Chip>
                                </Tooltip>
                            </div>
                        )}

                        {State === "processing" && (
                            <div className="absolute top-2 right-2">
                                <Tooltip className="dark" content="This image is a draft and is not yet published">
                                    <Chip startContent={<Scanning variant="Bold" size={20} />} size="md" className="animated-gradient chip-content blob-animation">
                                        <p>Analyzing...</p>
                                    </Chip>
                                </Tooltip>
                            </div>
                        )}

                        <div className="absolute bottom-0 right-0 flex flex-wrap justify-end m-1">
                            {image.objects && image.objects
                                .sort((a, b) => b.length - a.length) // Trier les objets par longueur décroissante
                                .slice(0, 1) // Prendre les deux premiers objets après le tri
                                .map((obj, objIndex) => (
                                    <Link
                                        key={objIndex}
                                        to={`/search?tags=${encodeURIComponent(obj)}`}
                                        className="text-decoration-none"
                                    >
                                        <CustomChip>
                                            {truncateText(obj, 18)}
                                        </CustomChip>
                                    </Link>
                                ))}

                            {image.metadata && image.metadata.Telescope && image.metadata.Telescope.slice(0, 1).map((obj, objIndex) => (
                                <Link
                                    key={objIndex}
                                    to={`/search?tags=${encodeURIComponent(obj)}`}
                                    className="text-decoration-none"
                                >
                                    <CustomChip color="default">
                                        {truncateText(obj, 18)}
                                    </CustomChip>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col justify-between flex-grow relative">
                        <div className="absolute inset-0 bg-black bg-opacity-50" ></div>
                        <CardBody className='purple-dark flex flex-col gap-2' >

                            <div className='flex flex-row' >


                                {isPublished === false ? (
                                    <div className="flex w-full gap-2">
                                        <Button className='w-full' onPress={handleEditDraft}>
                                            {t("edit")}
                                        </Button>

                                        <Button color="primary" className='w-full' onPress={handlePublish}>
                                            {t("publish")}
                                        </Button>
                                    </div>
                                ) : (
                                    <div className='flex gap-2' >
                                        <div>
                                            <LikeButton
                                                likedBy={currentUser?.username}
                                                likedById={currentUser?.uid}
                                                recipientId={image.author}
                                                imageId={image.id}
                                                initialLikes={image.like}
                                            />
                                        </div>
                                        <div>
                                            <Button size="sm" isIconOnly variant="light" className="w-full text-foreground " onClick={openModal} >
                                                <div className='flex gap-1 items-center' >
                                                    <Message size="24" />
                                                    <span className="text-sm font-bold">{commentCount}</span>
                                                </div>

                                            </Button>
                                        </div>
                                        <div>
                                            {isMobile ? (
                                                <Button size="sm" className="w-full text-foreground " isIconOnly onClick={handleShare} variant="light">

                                                    <ExportCurve size={24} />
                                                </Button>
                                            ) : (
                                                <Button size="sm" className="w-full text-foreground " isIconOnly onClick={handleCopyUrl} variant="light">

                                                    <ExportCurve size={24} />
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='flex flex-row gap-2 '>
                                <p className="flex flex-wrap">
                                    <Link to={`/profile/${image.username}`} className="text-sm cursor-pointer hover:underline font-medium">
                                        {image.username}
                                    </Link>
                                </p>
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm text-default-400">{formatRelativeTime(image.timestamp)}</p>
                                </div>
                            </div>

                        </CardBody>

                    </div>
                </Skeleton>
            </Card>


        </>
    );
};

export default ImageCard;
